<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<berth-form-header :modelPk="modelPk"></berth-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.berthblock.info') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<v-row dense class="mt-2">
								<v-col cols="4">
									<pui-date-field
										:id="`dateini-${modelName}`"
										v-model="model.dateini"
										:label="$t('form.berthblock.dateini')"
										required
										toplabel
										time
										:max="model.dateend"
									></pui-date-field>
								</v-col>
								<v-col cols="4">
									<pui-date-field
										:id="`dateend-${modelName}`"
										v-model="model.dateend"
										:label="$t('form.berthblock.dateend')"
										required
										toplabel
										time
										:min="model.dateini"
									></pui-date-field
								></v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4" v-if="enableBollards">
									<pui-select
										:id="`bollardini-${modelName}`"
										v-model="model"
										:label="$t('form.berthblock.bollardini')"
										:disabled="formDisabled"
										modelName="bollard"
										toplabel
										:modelFormMapping="{ id: 'bollardini' }"
										:itemsToSelect="[{ id: this.model.bollardini }]"
										itemValue="id"
										itemText="bolcode"
										:key="berthKey"
										:fixedFilter="berthFilter"
										:order="{ orderby: 'asc' }"
									></pui-select>
								</v-col>
								<v-col cols="4" v-if="enableBollards">
									<pui-select
										:id="`bollardend-${modelName}`"
										v-model="model"
										:label="$t('form.berthblock.bollardend')"
										:disabled="formDisabled"
										modelName="bollard"
										toplabel
										:modelFormMapping="{ id: 'bollardend' }"
										:itemsToSelect="[{ id: this.model.bollardend }]"
										itemValue="id"
										itemText="bolcode"
										:key="berthKey"
										:fixedFilter="berthFilter"
										:order="{ orderby: 'asc' }"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6">
									<pui-text-field
										:id="`name-${modelName}`"
										v-model="model.name"
										:label="$t('form.berthblock.name')"
										required
										toplabel
										maxlength="50"
									></pui-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import BerthblockFormHeader from './BerthblockFormHeader.vue';
import BerthFormHeader from '../berth/BerthFormHeader.vue';

export default {
	name: 'berthblock-form',
	mixins: [PuiFormMethodsMixin],
	components: { BerthblockFormHeader, BerthFormHeader },
	data() {
		return {
			modelName: 'berthblock',
			berthKey: 0,
			berthFilter: null,
			enableBollards: false
		};
	},
	methods: {
		afterGetData() {
			if (this.isCreatingElement) {
				this.model.locationid = JSON.parse(atob(this.$attrs.parentpk)).id;
				this.modelLoaded = true;
			}
			this.berthFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berth_id', op: 'eq', data: this.model.locationid }]
			};
			this.getBollardsFromBerth();
		},
		getBollardsFromBerth() {
			const opts = {
				model: 'berth',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.model.locationid
						}
					]
				}
			};

			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0].initbol && response.data.data[0].endbol) {
					this.enableBollards = true;
				}
			});
		}
	},
	computed: {},
	created() {}
};
</script>
